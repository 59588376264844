import database from '../config/firebase';
import React, {Fragment, useState, useEffect} from 'react';
import ShoutMessage from './ShoutMessage';
import moment from 'moment';

const Shout = () => {
    const [shouts, setShouts] = useState('');

    useEffect(() => {
        let currentShoutsRef;

        async function getShouts() {

            currentShoutsRef = database.ref(`shouts`).orderByChild(`create_date`).limitToLast(25);
            currentShoutsRef.on('value', function(snapshot) {
                if (!snapshot.val()) {
                    setShouts('');
                } else {
                    let currentShouts = [];

                    snapshot.forEach((childSnapshot) => {
                        currentShouts.push({
                            id: childSnapshot.key,
                            ...childSnapshot.val()
                        });
                    });
                    setShouts(currentShouts);
                }
            });

        }

        getShouts();

        return function cleanup() {
            if (currentShoutsRef != null) {
                currentShoutsRef.off();
            }
        }
    }, []);

   async function postShout(name, shout) {
        return database.ref(`shouts`).push({
            name,
            shout,
            create_date: Date.now()
        });
   } 


    const [formData, setFormData] = useState({
        shoutName: '',
        shoutText: ''
    });

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const { shoutName, shoutText } = formData;    

    const onSubmitNewShout = async e => {
        e.preventDefault();
        postShout(formData.shoutName, formData.shoutText);

        setFormData({shoutName:'', shoutText:''});
    };

    return (
        <Fragment>
        <div className="shout-input-box">
            This is a temporary Shout Box.  While the new World of Anime is under development, please post here and say hi!
            <p />
            <form>
                Name: <input
                    type="text"
                    className="shout-query-input"
                    placeholder="My Name"
                    name="shoutName"
                    value={shoutName}
                    onChange={e => onChange(e)}
                    />
                    <p />
                    Message:
                    <p />
                <textarea
                    name="shoutText"
                    rows="6"
                    cols="60"
                    value={shoutText}
                    onChange={e => onChange(e)}
                />

                <p><button className="post-container" onClick={e => onSubmitNewShout(e)}>Shout!</button></p>
            </form>
        </div>

        <div className="profile-comments-box">

        {shouts && shouts.length > 0 && 
            <Fragment>
            {shouts.sort(
                (a, b) =>
                moment(b.create_date) - moment(a.create_date)
               ).map(comment => (
                <ShoutMessage key={comment.id} comment={comment} />
            ))}
        </Fragment>}     
    </div>
        </Fragment>
    )
};

export default Shout;